import styled from 'styled-components';

interface ButtonProps {
  isBlocked: boolean;
}

export const ContainerAcoes = styled.div`
  width: 100px;
  height: 30px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
  transition: all 0.5s;
  button {
    background: none;
    border: none;
    svg {
      font-size: 20pt;
    }
  }
`;

export const ButtonRow = styled.button<ButtonProps>`
  border: none;
  background-color: transparent;
  color: #3d3d3d;
  cursor: ${({ isBlocked }) => (isBlocked ? 'not-allowed' : '')};

  &:nth-child(2) {
    margin-left: 10px;
  }
  &:hover {
    background-color: #f4f5f7 !important;
    color: #000;
    transition: 0.4s;
    text-decoration: underline;
  }
`;
